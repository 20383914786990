@import './variables.scss';
@import '~bootstrap/scss/bootstrap';
$bootstrap-icons-font-src:
  url('../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2')
    format('woff2'),
  url('../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff')
    format('woff');
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import './footer.scss';
@import './2fa.scss';
@import './passkey.scss';

body {
  @include media-breakpoint-up(sm) {
    background: rgb(7, 0, 128);
    background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  }
}

.form-text {
  .btn {
    font-size: 1em;
  }
}

.caption {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
}

.center-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
}

.status {
  color: red;
}

@media (min-width: 768px) {
  .br-line {
    display: none;
  }

  .mobile-only {
    display: none;
  }

  .center-container {
    min-width: 400px;
  }
}
