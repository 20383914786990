.passkey-item {
  background: #b4d2f4;
  transition: transform 0.1s ease; /* Animation */

  .small {
    font-size: 0.75rem;
    font-weight: 700;
  }

  &:hover {
    transform: scale(1.01);
  }
}

.clickable {
  transition: font-size 0.3s ease;
  &:hover {
    font-size: 1.2rem;
  }
}
