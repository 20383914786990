.select-list {
  .list-group-item {
    background-color: #fff;
    border: unset;
    border-radius: 0;
    border-bottom: 1px solid $main-color;
    line-height: normal;

    &:hover {
      background-color: #ddd;
    }

    &:last-child {
      border-bottom: 0;
    }

    .selection-heading {
      margin-bottom: 0.25rem;
    }

    .selection-description {
      font-size: 0.8rem;
    }
  }
}

.mmfa-wait-action-list {
  .mmfa-wait-action {
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }

    span.stretched-link {
      color: $main-color;
      font-weight: 700;
    }
  }
}
